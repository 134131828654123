import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsuarioService {
  pass = null;
  id = null;
  datos = null;

  private usuarios = environment.usuarios;
  constructor( private http:HttpClient ) { }

  iniciaSesion(data){
    return this.http.post(this.usuarios.inicia,data);
  }

  recuperarContra(data){
    return this.http.post(this.usuarios.recupera,data);
  }

  codigoTransparencia(data){
    return this.http.post(this.usuarios.codigo,data);
  }

  mandarCorreo(data){
    return this.http.post(this.usuarios.correo,data);
  }

  aceptoAviso(data){
    return this.http.post(this.usuarios.acepto,data);
  }

  actualiza(data){
    return this.http.post(this.usuarios.datos,data);
  }

  preguntarMunicipio(data){
    return this.http.post(this.usuarios.municipio,data);
  }  
  setId(data){
    this.id = data;
  }

  getId(){
    return this.id;
  }

  mandarFeedback(data){
    return this.http.post(this.usuarios.feedback,data);
  }

  mostrarPantalla(data){
    return this.http.post(this.usuarios.mostrar,data);
  }

  setAcceso(data){
    this.pass = data;
  }

  getAcceso() {
    return this.pass;
  }

  setDatos(data){
    this.datos = data;
  }

  getDatos(){
    return this.datos;
  }
}
