<ngb-carousel *ngIf="imagenes" class="carousel slide carousel-fade" [animation]="'fade'" [showNavigationArrows]="false"
	[showNavigationIndicators]="false">
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img class="fondo" [src]="imagenes[0]">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img class="fondo" [src]="imagenes[1]">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img class="fondo" [src]="imagenes[2]">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img class="fondo" [src]="imagenes[3]">
		</div>
	</ng-template>
</ngb-carousel>

<!--Open-->
<ng-template #content let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Login-->
	<div class="modal-body" *ngIf="tipo==1">
		<p class="mens">{{msj}}</p>
		<p class="menses2"><span class="menses">IMPORTANTE: </span>{{msj2}}</p>
	</div>
	<div class="modal-footer" *ngIf="tipo==1">
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Acepta')">Aceptar</button>
	</div>
	<!--Recupera contraseña-->
	<div class="modal-body" *ngIf="tipo==2">
		<p class="mens">{{msj}}</p>
		<form class="form-group" [formGroup]="rec">
			<input type="text" class="form-control in" formControlName="curp" maxlength="18"
				oninput="this.value = this.value.toUpperCase()" (input)="valida()">
		</form>
	</div>
	<div class="modal-footer" *ngIf="tipo==2 && !valido">
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
	<div class="modal-footer" *ngIf="tipo==2 && valido">
		<button type="button" class="btn btn-outline-dark continuar" (click)="recuperar(content2); c('Recupera')"
			onclick="this.disabled=true;">Recuperar</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Volver')">Volver</button>
	</div>

	<!--Código de Transparencias-->
	<div class="modal-body" *ngIf="tipo==3">
		<p class="mens">{{msj}}</p>
		<form class="form-group" [formGroup]="can">
			<input type="text" class="form-control in" formControlName="canjecodigo" maxlength="8"
				oninput="this.value = this.value.toUpperCase()" (input)="valida2()">
		</form>
	</div>
	<div class="modal-footer" *ngIf="tipo==3 && !valido2">
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
	<div class="modal-footer" *ngIf="tipo==3 && valido2">
		<button type="button" class="btn btn-outline-dark continuar"
			(click)="c('Canjear'); canje(content3)">Canjear</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Volver')">Volver</button>
	</div>

	<!--Enviar Feedback-->
	<div class="modal-body" *ngIf="tipo==4">
		<p class="mens">{{msj}}</p>
		<form class="form-group" [formGroup]="fb">
			<label class="dato" for="curp">Curp:</label>
			<input type="text" class="form-control in" formControlName="curp" maxlength="18"
				oninput="this.value = this.value.toUpperCase()" (input)="valida3()">
			<label class="dato" for="nombre">Nombre:</label>
			<input type="text" class="form-control in" formControlName="nombre"
				oninput="this.value = this.value.toUpperCase()" (input)="valida3()">
			<label class="dato" for="apellidoP">Apellido Paterno:</label>
			<input type="text" class="form-control in" formControlName="apellidoP"
				oninput="this.value = this.value.toUpperCase()" (input)="valida3()">
			<label class="dato" for="apellidoM">Apellido Materno:</label>
			<input type="text" class="form-control in" formControlName="apellidoM"
				oninput="this.value = this.value.toUpperCase()" (input)="valida3()">
			<label class="dato" for="correo">Correo electrónico:</label>
			<input type="text" class="form-control in" formControlName="correo"
				oninput="this.value = this.value.toLowerCase()" (input)="valida3()">
			<label class="dato" for="descripcion">Descripción del problema:</label>
			<input type="text" class="form-control in" formControlName="descripcion"
				oninput="this.value = this.value.toUpperCase()" placeholder="NO PUEDO ENTRAR A LA PLATAFORMA"
				(input)="valida3()">
		</form>
	</div>
	<div class="modal-footer" *ngIf="tipo==4 && !valido3">
		<label class="dato">Debes llenar todos los datos para continuar.</label>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
	<div class="modal-footer" *ngIf="tipo==4 && valido3">
		<button type="button" class="btn btn-outline-dark continuar"
			(click)="c('Enviar'); reporte(content4)">Enviar</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
</ng-template>

<!--Recuperación-->
<ng-template #content2 let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Envío de correo, todo Ok-->
	<div class="modal-body" *ngIf="tipo==5">
		<p class="mens">{{msj}}</p>
	</div>
	<div class="modal-footer" *ngIf="tipo==5">
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
	</div>
	<!--Curp no encontrado-->
	<div class="modal-body" *ngIf="tipo==6">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==6">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==6">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
	<!--Código no canjeado, solo Transparencia-->
	<div class="modal-body" *ngIf="tipo==7">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==7">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==7">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
	<!--Error en envío de correo, pero se muestra contraseña-->
	<div class="modal-body" *ngIf="tipo==8">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==8">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==8">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
	<!--Contraseña no encontrada, no transparencia-->
	<div class="modal-body" *ngIf="tipo==9">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==9">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==9">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
	<!--Error en busqueda de contraseña-->
	<div class="modal-body" *ngIf="tipo==10">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==10">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==10">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
</ng-template>

<!--Canje de código-->
<ng-template #content3 let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Código existente, solicita correo-->
	<div class="modal-body" *ngIf="tipo==11">
		<p class="mens">{{msj}}</p>
		<p class="dato2">CURP: <span class="mens2">{{canj_curp}}</span></p>
		<p class="dato2">Nombre: <span class="mens2">{{canj_nom}}</span></p>
		<form class="form-group" [formGroup]="mail">
			<label class="dato2" for="correo">Registra tu correo electrónico:</label>
			<input type="text" class="form-control in" formControlName="correo"
				oninput="this.value = this.value.toLowerCase()">
			<br>
			<input class="form-check-input in rb" type="radio" formControlName="aviso" id="aviso1" value="TRUE"
				required>
			<label class="form-check-label dato3" for="aviso1">Estoy de acuerdo con el tratamiento de mis datos
				personales sensibles y con el tratamiento de mis datos personales con fines de promoción y publicidad de
				conformidad con el aviso de privacidad de Bécalos publicado en <a
					href="https://becalos.mx/aviso-de-privacidad/" target="_blank"><b>becalos.mx</b></a>.</label>
			<br><br>
			<input class="form-check-input in rb" type="radio" formControlName="manual" id="aviso2" value="TRUE"
				required>
			<label class="form-check-label dato3" for="aviso2">He leído y descargado el <a
					href="https://storage.googleapis.com/nemi_desarrollo/becalos-plantillas/3.%20Becarios%20de%20nuevo%20ingreso.pdf"
					target="_blank"><b>manual de usuario</b></a>.</label>
		</form>
	</div>

	<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando clic
		<span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
	<div class="modal-footer" *ngIf="tipo==11 && this.mail.invalid">
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Volver')">Volver</button>
	</div>

	<div class="modal-footer" *ngIf="tipo==11 && this.mail.valid">
		<button type="button" class="btn btn-outline-dark continuar"
			(click)="c('canje'); actualiza(content5)">Continuar</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Volver')">Volver</button>
	</div>
	<!--Error correo-->
	<div class="modal-body" *ngIf="tipo==12">
		<p class="mens">{{msj}}</p>
	</div>
	<div *ngIf="tipo==12">
		<p class="feedback">En caso de presentar alguna incidencia en tus datos o en el acceso solicita soporte dando
			clic <span class="botfeedback" (click)="c('Feedback'); open(content,'feedback')">aquí.</span></p>
		<div class="modal-footer" *ngIf="tipo==12">
			<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
		</div>
	</div>
</ng-template>

<!--Confirmacion de feedback-->
<ng-template #content4 let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Correo enviado-->
	<div class="modal-body">
		<p class="mens">{{msj}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Ok</button>
	</div>
</ng-template>

<!--Actualización de correo por canje de código -->
<ng-template #content5 let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Correo enviado-->
	<div class="modal-body" *ngIf="tipo==13">
		<p class="mens">{{msj}}</p>
	</div>
	<div class="modal-footer" *ngIf="tipo==13">
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Ok</button>
	</div>
	<!--Error en envio de correo-->
	<div class="modal-body" *ngIf="tipo==14">
		<p class="mens">{{msj}}</p>
	</div>
	<div class="modal-footer" *ngIf="tipo==14">En caso de presentar alguna incidencia en tus datos o en el acceso
		solicita soporte dando clic
		<button type="button" class="btn btn-outline-dark continuar"
			(click)="c('Feedback'); open(content,'feedback')">aquí</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
	</div>
</ng-template>

<div class="container">
	<div class="center">
		<h1><span class="azulMagentaClaro">C</span><span>OMUNIDAD </span><span
				class="azulMagentaClaro">B</span><span>ÉCALOS</span></h1>
		<form class="form-group" [formGroup]="entrar">
			<div class="llenar">
				<div class="txt_field">
					<input type="text" class="form-control in" formControlName="correo">
					<span></span>
					<label>Correo electrónico</label>
				</div>
				<div class="txt_field">
					<input type="password" class="form-control in" formControlName="contra">
					<span></span>
					<label>Contraseña</label>
				</div>
			</div>
			<div class="pass" (click)="open(content,'recupera')">¿Olvidaste tu contraseña?</div>
			<button type="button" class="myButtonRegistro" (click)="open(content,'login')">Ingresar</button>
			<div class="pass" (click)="open(content,'codigo')">Canjear código de transparencia</div>
			<div class="pass" (click)="open(content,'feedback')">Reportar problema</div>
		</form>
	</div>
</div>