import { Component, OnInit } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuarioService } from 'src/app/Services/usuario.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  entrar: FormGroup;
  rec: FormGroup;
  can: FormGroup;
  fb: FormGroup;
  mail: FormGroup;
  msj;
  msj2;
  tipo;
  ruta;
  canj_curp;
  canj_nom;
  valido = false;
  valido2 = false;
  valido3 = false;
  valido4 = false;

  imagenes = ['assets/imagenes/fondo1.jpg',
    'assets/imagenes/fondo2.jpg',
    'assets/imagenes/fondo3.jpg',
    'assets/imagenes/fondo4.jpg',];

  constructor(modConfig: NgbModalConfig, private modalService: NgbModal, private config: NgbCarouselConfig, private parametro: ActivatedRoute, private router: Router, private _formBuilder: FormBuilder, private usService: UsuarioService) {
    config.interval = 5000;
    modConfig.backdrop = 'static';
    modConfig.keyboard = false;
    this.entrar = this._formBuilder.group({
      correo: ['', [Validators.email, Validators.required]],
      contra: ['', [Validators.minLength(6), Validators.required]]
    });
    this.rec = this._formBuilder.group({
      curp: ['', [Validators.minLength(18), Validators.maxLength(18), Validators.required]]
    });
    this.can = this._formBuilder.group({
      canjecodigo: ['', [Validators.minLength(8), Validators.required]]
    });
    this.mail = this._formBuilder.group({
      correo: ['', [Validators.email, Validators.required]],
      aviso: ['', [Validators.required]],
      manual: ['', [Validators.required]]
    });
    this.fb = this._formBuilder.group({
      curp: ['', [Validators.minLength(18), Validators.maxLength(18), Validators.required]],
      nombre: ['', [Validators.minLength(4), Validators.required]],
      apellidoP: ['', [Validators.minLength(4), Validators.required]],
      apellidoM: ['', []],
      correo: ['', [Validators.minLength(4), Validators.required]],
      descripcion: ['', [Validators.minLength(4), Validators.required]]
    });
  }

  ngOnInit(): void {
  }

  get fForm() {
    return this.mail.controls;
  }

  valida() {
    let form = this.rec.valid;
    this.valido = form;
  }

  valida2() {
    let form = this.can.valid;
    this.valido2 = form;
  }

  valida3() {
    let form = this.fb.valid;
    this.valido3 = form;
    console.log(this.valida3);
    
  }

  recuperar(content2) {
    console.log(this.rec.value);
    this.usService.recuperarContra(this.rec.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.tipo = 5;
        this.msj = 'Hemos enviado tu contraseña de acceso a tu correo electrónico registrado: ' + data.correo;
        this.modalService.open(content2);
      }
      else if (data.mensaje == 1) {
        this.tipo = 6;
        this.msj = 'No existe esta CURP en nuestros registros.';
        this.modalService.open(content2);
      }
      else if (data.mensaje == 2) {
        this.tipo = 7;
        this.msj = "* Aún no canjeas tu código de transparencia, puedes hacerlo en la pantalla principal.";
        this.modalService.open(content2);
      }
      else if (data.mensaje == 3) {
        this.tipo = 8;
        this.msj = 'Tuvimos un problema para enviarte tu contraseña por correo, sin embargo para continuar con tu proceso, tu contraseña es: ' + data.pass;
        this.modalService.open(content2);
      }
      else if (data.mensaje == 4) {
        this.tipo = 9;
        this.msj = "* Verifica los datos de acceso enviados a tú correo electrónico, en caso de no haber recibido ningún correo de nuestra parte con tus datos de acceso, favor de reportar el incidente.";
        this.modalService.open(content2);
      }
      else {
        this.tipo = 10;
        this.msj = "* Error inesperado, favor de reportar este incidente.";
        this.modalService.open(content2);
      }
    })
    this.rec.reset();
  }

  canje(content3) {
    console.log(this.can.value);
    this.usService.codigoTransparencia(this.can.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.tipo = 11;
        this.usService.setId(data.id);
        this.canj_curp = data.curp;
        this.canj_nom = data.nom + " " + data.app + " " + data.apm;
        this.msj = 'Canjeando código de transparencia';
        this.modalService.open(content3);
      }
      else if (data.mensaje == 1) {
        this.tipo = 12;
        this.msj = "* El código de transparencia ingresado no es correcto, favor de verificar.";
        this.modalService.open(content3);
      }
      else if (data.mensaje == 2) {
        this.tipo = 12;
        this.msj = "* Existe un problema con tu 'Pre-carga' favor de contactarnos al correo electrónico contactobecarios@becalos.mx.";
        this.modalService.open(content3);
      }
      else {
        /*this.tipo = 11;
        this.canj_curp = "QWER123456ASDFGH00";
        this.canj_nom = "Pancho Pérez Pérez";
        this.msj = 'Canjeando código transparencia';
        this.modalService.open(content3);*/
        this.tipo = 12;
        this.msj = "* Error inesperado, favor de intentar más tarde.";
        this.modalService.open(content3);
      }
    })
    this.can.reset();
  }

  actualiza(content5) {
    console.log(this.mail.value);
    this.mail.value.id = this.usService.getId();
    this.usService.mandarCorreo(this.mail.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.tipo = 13;
        this.msj = 'Tu código de transparencia fue canjeado con éxito. Recibirás tus datos de acceso para esta plataforma, al correo que acabas de registrar.';
        this.modalService.open(content5);
      }
      else {
        this.msj = "* El código de transparencia ingresado no es correcto, favor de verificar.";
        this.modalService.open(content5);
      }
    })

  }

  reporte(content4) {
    console.log(this.fb.value);
    this.usService.mandarFeedback(this.fb.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.msj = 'Tu reporte fue enviado con éxito, danos la oportunidad de verificar la información y atenderemos tu situación!!!';
        this.modalService.open(content4);
      }
      else if (data.mensaje == 1) {
        this.msj = 'Estimado usuario, favor de verificar tu CURP, el uso de esta plataforma es exclusiva de miembros de la comunidad de Bécalos.';
        this.modalService.open(content4);
      }
      else if (data.mensaje == 2) {
        this.msj = 'Tu reporte fue enviado con éxito, pero tenemos un problema al mandarte tu acuse por correo electrónico, no te preocupes, danos la oportunidad de verificar la información y atenderemos tu situación!!!';
        this.modalService.open(content4);
      }
      else {
        this.msj = "* Hay un problema en el envío de tu reporte, favor de verificar la información e intetar de nuevo.";
        this.modalService.open(content4);
      }
    })
    this.fb.reset();
  }

  cancelar() {
    this.rec.reset();
    this.can.reset();
    this.mail.reset();
    this.fb.reset();
  }

  open(content, type) {
    let form = this.entrar;
    let mail = form.value.correo;
    let pass = form.value.contra;
    this.msj = '';
    this.msj2 = '';
    if (type == 'login') {
      this.tipo = 1;
      if (mail == "") {
        this.msj = '* El correo electrónico es obligatorio.';
      }
      else if (pass == "") {
        this.msj = '* La contraseña es obligatoria.';
      }
      else if (this.entrar.invalid) {
        this.msj = '* El usuario o la contraseña no son correctos.';
      }
      else {
        console.log(this.entrar.value);
        this.usService.iniciaSesion(this.entrar.value).subscribe((data: any) => {
          console.log(data);
          if (data.mensaje == 0) {
            this.usService.setDatos(data.datos);
            this.usService.setAcceso("Ok");
            if (data.genero == "M")
              this.msj = 'Bienvenida a la Comunidad Bécalos';
            else if (data.genero == "H")
              this.msj = 'Bienvenido a la Comunidad Bécalos';
            else
              this.msj = 'Accediendo a la Comunidad Bécalos';
            this.msj2 = 'La sesión se cerrará después de 10 minutos de inactividad y tus cambios no serán guardados.';
            this.entrar.reset();
            this.router.navigate([`perfil/usuario/${data.mensaje}`]);
          }
          else if (data.mensaje == 1) {
            this.msj = "* El correo no está registrado en la comunidad Bécalos.";
          }
          else if (data.mensaje == 2) {
            this.msj = "* No cuentas con registro en la comunidad Bécalos.";
          }
          else if (data.mensaje == 3) {
            this.msj = "* La contraseña no es correcta, puedes recuperarla en la pantalla principal.";
          }
          else {
            this.msj = "* El usuario o la contraseña no son correctos.";
          }
        })
      }
      this.modalService.open(content);
    }
    else if (type == 'recupera') {
      this.valido = false;
      this.tipo = 2;
      this.msj = 'Favor de escribir tu Curp para enviarte por  correo electrónico tu contraseña.';
      this.modalService.open(content);
    }
    else if (type == 'codigo') {
      this.valido2 = false;
      this.tipo = 3;
      this.msj = 'Ingresa tu código de transparencia para continuar.';
      this.modalService.open(content);
    }
    else if (type == 'feedback') {
      this.valido3 = false;
      this.tipo = 4;
      this.msj = 'Por favor proporcionanos la información solicitada y generará tu reporte, en un plazo de 24 a 48 horas estarás recibiendo actualización de tu reporte.';
      this.modalService.open(content);
    }
  }

}