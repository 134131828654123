import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { UsuarioService } from 'src/app/Services/usuario.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-contenido',
  templateUrl: './contenido.component.html',
  styleUrls: ['./contenido.component.css'],
})
export class ContenidoComponent implements OnInit {
  [x: string]: any;
  identidad: FormGroup;
  aceptarAviso: FormGroup;
  actualizaDatos1: FormGroup;
  actualizaDatos2: FormGroup;
  fb: FormGroup;
  fecha = new Date();
  anio = this.fecha.getFullYear();
  msj = "";
  msj2 = "";
  msj3 = "";
  msj4 = "";
  msj5 = "";
  iFrameUrl;
  valido = 0;
  idsver;
  ids;
  cadena;
  idsver2;
  ids2;
  cadena2;
  tipo;
  valido2 = false;
  valido3 = false;
  datos;

  listaEstado = [{ id: 1, name: 'Aguascalientes' },
  { id: 2, name: 'Baja California' },
  { id: 3, name: 'Baja California Sur' },
  { id: 4, name: 'Campeche' },
  { id: 5, name: 'Coahuila' },
  { id: 6, name: 'Colima' },
  { id: 7, name: 'Chiapas' },
  { id: 8, name: 'Chihuahua' },
  { id: 9, name: 'Ciudad de México' },
  { id: 10, name: 'Durango' },
  { id: 11, name: 'Guanajuato' },
  { id: 12, name: 'Guerrero' },
  { id: 13, name: 'Hidalgo' },
  { id: 14, name: 'Jalisco' },
  { id: 15, name: 'México' },
  { id: 16, name: 'Michoacán' },
  { id: 17, name: 'Morelos' },
  { id: 18, name: 'Nayarit' },
  { id: 19, name: 'Nuevo León' },
  { id: 20, name: 'Oaxaca' },
  { id: 21, name: 'Puebla' },
  { id: 22, name: 'Querétaro' },
  { id: 23, name: 'Quintana Roo' },
  { id: 24, name: 'San Luis Potosí' },
  { id: 25, name: 'Sinaloa' },
  { id: 26, name: 'Sonora' },
  { id: 27, name: 'Tabasco' },
  { id: 28, name: 'Tamaulipas' },
  { id: 29, name: 'Tlaxcala' },
  { id: 30, name: 'Veracruz' },
  { id: 31, name: 'Yucatán' },
  { id: 32, name: 'Zacatecas' }];

  municipios;
  estadoRep: FormGroup;

  constructor(private _sanitizer: DomSanitizer, modConfig: NgbModalConfig, private modalService: NgbModal, private parametro: ActivatedRoute, private usService: UsuarioService, private _formBuilder: FormBuilder, private router: Router) {
    this.identidad = this._formBuilder.group({});
    this.aceptarAviso = this._formBuilder.group({});
    this.estadoRep = this._formBuilder.group({});
    this.actualizaDatos1 = this._formBuilder.group({
      programa: ['', []],
      periodo: ['', []],
      grado: ['', [Validators.required]],
      promedio: ['', [Validators.required]],
      tel1: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      tel2: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      facebook: ['', [Validators.minLength(4), Validators.required]],
      correo1: ['', []],
      correo2: ['', [Validators.email, Validators.required]],
      aviso: ['', [Validators.required]],
      manual: ['', [Validators.required]]
    });
    this.actualizaDatos2 = this._formBuilder.group({
      contraNueva: ['', [Validators.minLength(6), Validators.required]],
      contraNueva2: ['', [Validators.minLength(6), Validators.required]],
      estado: ['', [Validators.required]],
      municipio: ['', [Validators.required]],
      tel1: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      tel2: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.required]],
      facebook: ['', [Validators.minLength(4), Validators.required]],
      correo1: ['', []],
      correo2: ['', [Validators.email, Validators.required]],
      escuela: ['', []],
      carrera: ['', []],
      programa: ['', []],
      grado: ['', []],
      periodo: ['', []],
      duracion: ['', []],
      promedio: ['', []],
      aviso: ['', [Validators.required]],
      manual: ['', [Validators.required]],
      ley: ['', []]
    });
    this.fb = this._formBuilder.group({
      curp: ['', [Validators.minLength(18), Validators.maxLength(18), Validators.required]],
      nombre: ['', [Validators.minLength(4), Validators.required]],
      apellidoP: ['', [Validators.minLength(4), Validators.required]],
      apellidoM: ['', []],
      correo: ['', [Validators.minLength(4), Validators.required]],
      descripcion: ['', [Validators.minLength(4), Validators.required]]
    });
    this.datos = this.usService.getDatos();
  }

  ngOnInit(): void {
  }

  home() {
    this.valido = 0;
  }

  trerMunicipio() {
    let form = this.actualizaDatos2;
    let estado = form.value.estado;
    console.log("Enviando estado: " + parseInt(estado));
    this.estadoRep.value.id = parseInt(estado);
    console.log(this.estadoRep.value);
    this.usService.preguntarMunicipio(this.estadoRep.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.municipios = data.datos;
      }
    })
  }

  valida2() {
    console.log(this.actualizaDatos1.valid);
    let form = this.actualizaDatos1.valid;
    this.valido2 = form;
  }

  valida3() {
    console.log(this.actualizaDatos2.valid);
    let form = this.actualizaDatos2.valid;
    this.valido3 = form;
  }

  becas(content2) {
    this.tipo = 0;
    console.log(this.datos);
    this.msj = 'Selecciona la versión que quieres visualizar.';
    this.msj2 = 'Recuerda que la sesión se cerrará después de 10 minutos de inactividad y tus cambios no serán guardados.';
    this.modalService.open(content2);
  }

  acepto(content2, id) {
    this.aceptarAviso.value.id = this.usService.getAcceso();
    console.log(this.aceptarAviso.value);
    this.usService.aceptoAviso(this.aceptarAviso.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.tipo = 0;
        this.msj = "Gracias, ahora puedes actualizar tus datos.";
        this.modalService.open(content2);
      }
      else {
        this.tipo = 1;
        this.msj = 'Tenemos un problema, apoyanos reportandolo.';
        this.modalService.open(content2);
      }
    })
  }

  manuales(content2) {
    this.tipo = 2;
    this.msj = 'Manuales de usuario';
    this.modalService.open(content2);
  }

  actualizaNuevo(content2) {
    //this.actualizaDatos2.value.id = this.parametro.snapshot.paramMap.get("id");
    this.actualizaDatos2.value.id = this.usService.getAcceso();
    console.log(this.actualizaDatos2.value);
    let form = this.actualizaDatos2;
    let contra1 = form.value.contraNueva;
    let contra2 = form.value.contraNueva2;
    if (contra1 === contra2 && contra1 != "") {
      this.usService.actualiza(this.actualizaDatos2.value).subscribe((data: any) => {
        console.log(data);
        if (data.mensaje == 0) {
          this.tipo = 1;
          this.msj = "Gracias, ahora puedes visualizar tu perfil en Mi Beca.";
          this.modalService.open(content2);
        }
        else if (data.mensaje == 1) {
          this.tipo = 1;
          this.msj = 'Tenemos un problema con alguno de los siguientes datos: correo adicional, alguno de los 2 telefonos de contacto, estado o municipio.';
          this.modalService.open(content2);
        }
        else if (data.mensaje == 2) {
          this.tipo = 1;
          this.msj = 'Tenemos un problema con alguno de los siguientes datos: facebook o Nueva contraseña de SOYCOMUNIDAD.';
          this.modalService.open(content2);
        }
        else {
          this.tipo = 1;
          this.msj = 'Tenemos un problema inesperado, apoyanos reportandolo.';
          this.modalService.open(content2);
        }
      })
    }
    else {
      this.tipo = 1;
      this.msj = 'Las contraseñas deben coincidir exactamente.';
      this.modalService.open(content2);
    }
  }

  actualizaRenovante(content2) {
    //this.actualizaDatos1.value.id = this.parametro.snapshot.paramMap.get("id");
    this.actualizaDatos1.value.id = this.usService.getAcceso();
    console.log(this.actualizaDatos1.value);
    this.usService.actualiza(this.actualizaDatos1.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.tipo = 1;
        this.msj = "Gracias, ahora puedes visualizar tu perfil en Mi Beca.";
        this.modalService.open(content2);
      }
      else if (data.mensaje == 1) {
        this.tipo = 1;
        this.msj = 'Tenemos un problema con alguno de los siguientes datos: correo adicional o alguno de los 2 telefonos de contacto.';
        this.modalService.open(content2);
      }
      else if (data.mensaje == 3) {
        this.tipo = 1;
        this.msj = 'Tenemos un problema con alguno de los siguientes datos: Promedio o grado actual.';
        this.modalService.open(content2);
      }
      else {
        this.tipo = 1;
        this.msj = 'Tenemos un problema inesperado, apoyanos reportandolo.';
        this.modalService.open(content2);
      }
    })
  }

  verPerfil(id, content) {
    this.identidad.value.id = id;
    this.usService.setAcceso(id);
    console.log(this.identidad.value)
    this.usService.mostrarPantalla(this.identidad.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.iFrameUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://qa.pagodirecto.becalos.com/PagoDirecto/free/acceso/index.jsp?json=' + data.json + '&sha1=' + data.sha1 + '&sha2=' + data.sha2 + '&fechaHora=' + data.fecha);
        console.log('https://qa.pagodirecto.becalos.com/PagoDirecto/free/acceso/index.jsp?json=' + data.json + '&sha1=' + data.sha1 + '&sha2=' + data.sha2 + '&fechaHora=' + data.fecha);
        this.valido = 1;
      }
      else if (data.mensaje == 1) {
        this.tipo = 1;
        this.msj = "Recuerda, para completar el proceso debes realizar los siguientes pasos:";
        this.msj2 = "1- En tu primer ingreso a la Comunidad Bécalos, deberás dar clic en la pestaña (Mi Beca) para registrar tus datos personales y de contacto.";
        this.msj3 = "2- Una vez registrados tus datos, vuelve a dar clic en la pestaña (Mi Beca) y podrás visualizar tu perfil de transparencia. Deberás ingresar a tu perfil de transparencia para responder las encuestas según el calendario que se muestra en la página principal.";
        this.msj4 = "* Este requisito es obligatorio y esta descrito en el manual que acabas de aceptar, en caso de no cumplir en tiempo y forma causará baja inmediata del programa.";
        this.msj5 = "Agradecemos tu participación y compromiso.";
        //this.msj = "Recuerda, en tu primer ingreso a la SOYCOMUNIDAD BÉCALOS, deberás seleccionar la pestaña Mi Beca para actualizar tu información, una vez lo hagas, podrás visualizar en la misma pestaña (Mi Beca) tu perfil de Transparencia donde periódicamente deberás ingresar para comprobar que hayas recibido tu PAGO DE BECA. Este requisito es obligatorio y esta descrito en el reglamento que acabas de aceptar, en caso de no cumplir con este requerimiento se causará baja inmediata del programa. Recibirás al menos tres correos electrónicos donde te solicitaremos que accedas con tu usuario y contraseña a SOYCOMUNIDAD BECALOS , para realizar las diferentes encuestas en la pestaña Mi Beca. Agradecemos tu participación y compromiso";
        //this.msj = "Recuerda, para completar el proceso debes realizar los siguientes pasos: 1- En tu primer ingreso a la Comunidad Bécalos, deberás dar clic en la pestaña (Mi Beca) para registrar tus datos personales y de contacto. 2- Una vez registrados tus datos, vuelve a dar clic en la pestaña (Mi Beca) y podrás visualizar tu perfil de Transparencia. Deberás ingresar periódicamente a tu perfil de Transparencia para responder las encuestas correspondientes al periodo de tu apoyo educativo. * Este requisito es obligatorio y esta descrito en el reglamento que acabas de aceptar, en caso de no cumplir se causará baja inmediata del programa. Recibirás correos electrónicos periodicamente donde te daremos más información. Agradecemos tu participación y compromiso.";
        this.modalService.open(content);
      }
      else if (data.mensaje == 2) {
        this.tipo = 2;
        this.msj = "Actualiza tu información";
        this.actualizaDatos1.patchValue({ correo1: data.correo });
        this.actualizaDatos1.patchValue({ programa: data.programa });
        this.actualizaDatos1.patchValue({ grado: data.grado });
        if (data.periodo == "s" || data.periodo == "S") data.periodo = "SEMESTRE";
        if (data.periodo == "c" || data.periodo == "C") data.periodo = "CUATRIMESTRE";
        if (data.periodo == "t" || data.periodo == "T") data.periodo = "TRIMESTRE";
        if (data.periodo == "a" || data.periodo == "A") data.periodo = "AÑO";
        this.actualizaDatos1.patchValue({ periodo: data.periodo });
        this.actualizaDatos1.patchValue({ promedio: data.promedio });
        this.modalService.open(content);
      }
      else if (data.mensaje == 3) {
        this.tipo = 3;
        this.msj = "Actualiza tu información";
        this.actualizaDatos2.patchValue({ correo1: data.correo });
        this.actualizaDatos2.patchValue({ escuela: data.escuela });
        this.actualizaDatos2.patchValue({ programa: data.programa });
        this.actualizaDatos2.patchValue({ carrera: data.carrera });
        this.actualizaDatos2.patchValue({ grado: data.grado });
        if (data.periodo == "s" || data.periodo == "S") data.periodo = "SEMESTRE";
        if (data.periodo == "c" || data.periodo == "C") data.periodo = "CUATRIMESTRE";
        if (data.periodo == "t" || data.periodo == "T") data.periodo = "TRIMESTRE";
        if (data.periodo == "a" || data.periodo == "A") data.periodo = "AÑO";
        this.actualizaDatos2.patchValue({ periodo: data.periodo });
        this.actualizaDatos2.patchValue({ duracion: data.duracion });
        this.actualizaDatos2.patchValue({ promedio: data.promedio });
        this.modalService.open(content);
      }
      else if (data.mensaje == 4) {
        this.iFrameUrl = this._sanitizer.bypassSecurityTrustResourceUrl('https://qa.transparencia.becalos.com/Transparencia/free/acceso/index.jsp?json=' + data.json + '&fechaHora=' + data.fecha + '&sha1=' + data.sha1 + '&sha2=' + data.sha2);
        console.log('https://qa.transparencia.becalos.com/Transparencia/free/acceso/index.jsp?json=' + data.json + '&sha1=' + data.sha1 + '&sha2=' + data.sha2 + '&fechaHora=' + data.fecha);
        this.valido = 1;
      }
      else {
        this.tipo = 0;
        this.msj = 'Tenemos un problema, apoyanos reportandolo.';
        this.modalService.open(content);
      }
    })
  }

  valida4() {
    let form = this.fb.valid;
    this.valido3 = form;
    console.log(this.valido3);
  }

  cerrarSesion(content3) {
    this.msj = '¿Deseas cerrar tu sesión?';
    this.modalService.open(content3);
  }

  salir() {
    this.usService.setAcceso("");
    this.router.navigate([`home`]);
  }

  reporte(content5) {
    console.log(this.fb.value);
    this.usService.mandarFeedback(this.fb.value).subscribe((data: any) => {
      console.log(data);
      if (data.mensaje == 0) {
        this.msj = 'Tu reporte fue enviado con éxito, danos la oportunidad de verificar la información y atenderemos tu situación!!!';
        this.modalService.open(content5);
      }
      else if (data.mensaje == 1) {
        this.msj = 'Estimado usuario, favor de verificar tu CURP, el uso de esta plataforma es exclusiva de miembros de la comunidad de Bécalos.';
        this.modalService.open(content5);
      }
      else if (data.mensaje == 2) {
        this.msj = 'Tu reporte fue enviado con éxito, pero tenemos un problema al mandarte tu acuse por correo electrónico, no te preocupes, danos la oportunidad de verificar la información y atenderemos tu situación!!!';
        this.modalService.open(content5);
      }
      else {
        this.msj = "* Hay un problema en el envío de tu reporte, favor de verificar la información e intetar de nuevo.";
        this.modalService.open(content5);
      }
    })
    this.fb.reset();
  }

  feedback(content4) {
    this.valido3 = false;
    this.tipo = 4;
    this.msj = 'Por favor proporcionanos la información solicitada y generará tu reporte, en un plazo de 24 a 48 horas estarás recibiendo actualización de tu reporte.';
    this.modalService.open(content4);
  }
}
