<ng-template #content let-c="close" let-d="dismiss">
    <div class="modal-header">
        <img src="assets/imagenes/logo.png">
        <h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
    </div>
    <!--Aviso-->
    <div class="modal-body" *ngIf="tipo==1">
        <p class="mens">{{msj}}</p>
        <p class="mens paso">{{msj2}}</p>
        <p class="mens paso">{{msj3}}</p>
        <p class="mens paso">{{msj4}}</p>
        <p class="mens">{{msj5}}</p>
    </div>
    <div class="modal-footer" *ngIf="tipo==1">
        <button type="button" class="btn btn-outline-dark continuar"
            (click)="acepto(content2); c('Volver')">Aceptar</button>
    </div>
    <!--Renovante-->
    <div class="modal-body" *ngIf="tipo==2">
        <p class="mens">{{msj}}</p>
        <form class="form-group" [formGroup]="actualizaDatos1">
            <label class="dato" for="programa">Programa:</label>
            <input type="text" class="form-control in" formControlName="programa" disabled="disabled">
            <label class="dato" for="periodo">Periodo actual:</label>
            <input type="text" class="form-control in" formControlName="periodo" disabled="disabled">
            <label class="dato" for="grado">Semestre / Cuatrimestre / Año actual:</label>
            <input type="text" class="form-control in" formControlName="grado"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida2()">
            <label class="dato" for="promedio">Promedio actual:</label>
            <input type="text" class="form-control in" formControlName="promedio"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida2()">
            <label class="dato" for="tel1">Teléfono de contacto:</label>
            <input type="text" class="form-control in" formControlName="tel1" maxlength="10" minlength="10"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida2()">
            <label class="dato" for="tel2">Teléfono adicional:</label>
            <input type="text" class="form-control in" formControlName="tel2" maxlength="10" minlength="10"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida2()">
            <label class="dato" for="facebook">Facebook:</label>
            <input type="text" class="form-control in" formControlName="facebook" (input)="valida2()">
            <label class="dato" for="correo1">Correo de contacto principal:</label>
            <input type="text" class="form-control in" formControlName="correo1" disabled="disabled">
            <label class="dato" for="correo2">Correo de contacto secundario:</label>
            <input type="text" class="form-control in" formControlName="correo2"
                oninput="this.value = this.value.toLowerCase()" (input)="valida2()">
            <br>
            <input class="form-check-input in rb" type="radio" formControlName="aviso" id="aviso1" value="TRUE"
                required>
            <label class="form-check-label dato3" for="aviso1">Estoy de acuerdo con el tratamiento de mis datos
                personales sensibles y con el tratamiento de mis datos personales con fines de promoción y publicidad de
                conformidad con el aviso de privacidad de Bécalos publicado en <a
                    href="https://becalos.mx/aviso-de-privacidad/" target="_blank"><b>becalos.mx</b></a>.</label>
            <br><br>
            <input class="form-check-input in rb" type="radio" formControlName="manual" id="aviso2" value="TRUE"
                required>
            <label class="form-check-label dato3" for="aviso2">Declaro bajo protesta de decir verdad que la información
                y documentación proporcionada es verídica, <br> por lo que en caso de existir falsedad en ella se me
                dará de baja del programa y perderé el apoyo.</label>
            <br>
        </form>
    </div>
    <div class="modal-footer" *ngIf="tipo==2 && !valido2">
        <button type="button" class="btn btn-outline-dark continuar"
            (click)="actualizaRenovante(content2); c('Volver')">Guardar</button>
    </div>
    <!--Nuevo-->
    <div class="modal-body" *ngIf="tipo==3">
        <p class="mens">{{msj}}</p>
        <form class="form-group" [formGroup]="actualizaDatos2">
            <label class="dato" for="contraNueva">Nueva contraseña de SOYCOMUNIDAD:</label>
            <input type="password" class="form-control in" formControlName="contraNueva" (input)="valida3()">
            <label class="dato" for="contraNueva2">Confirma nueva contraseña de SOYCOMUNIDAD:</label>
            <input type="password" class="form-control in" formControlName="contraNueva2" (input)="valida3()">
            <label class="dato" for="estado">Entidad Federativa de residencia:</label>
            <select class="form-control dato4" formControlName="estado" (change)='trerMunicipio()'>
                <option selected="true" disabled="disabled"> Elige una opción</option>
                <option *ngFor="let lest of listaEstado" [value]=lest.id>{{lest.name}}</option>
            </select>
            <label class="dato" for="municipio">Municipio o alcaldia de residencia:</label>
            <select class="form-control dato4" formControlName="municipio">
                <option *ngFor="let item of municipios" [value]=item.id_municipio>{{item.municipio}}</option>
            </select>
            <label class="dato" for="tel1">Teléfono de contacto:</label>
            <input type="text" class="form-control in" formControlName="tel1" maxlength="10" minlength="10"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida3()">
            <label class="dato" for="tel2">Teléfono adicional:</label>
            <input type="text" class="form-control in" formControlName="tel2" maxlength="10" minlength="10"
                onkeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;"
                (input)="valida3()">
            <label class="dato" for="facebook">Facebook:</label>
            <input type="text" class="form-control in" formControlName="facebook"
                oninput="this.value = this.value.toLowerCase()" (input)="valida3()">
            <label class="dato" for="correo1">Correo de contacto principal (este correo será tu usuario para acceder a la plataforma):</label>
            <input type="text" class="form-control in" formControlName="correo1" disabled="disabled">
            <label class="dato" for="correo2">Correo de contacto secundario:</label>
            <input type="text" class="form-control in" formControlName="correo2"
                oninput="this.value = this.value.toLowerCase()" (input)="valida3()">
            <label class="dato" for="escuela">Escuela:</label>
            <input type="text" class="form-control in" formControlName="escuela" disabled="disabled">
            <label class="dato" for="carrera">Carrera:</label>
            <input type="text" class="form-control in" formControlName="carrera" disabled="disabled">
            <label class="dato" for="periodo">Periodo actual:</label>
            <input type="text" class="form-control in" formControlName="periodo" disabled="disabled">
            <label class="dato" for="grado">Grado actual:</label>
            <input type="text" class="form-control in" formControlName="grado" disabled="disabled">
            <label class="dato" for="duracion">Duración total de carrera (solo número):</label>
            <input type="text" class="form-control in" formControlName="duracion" disabled="disabled">
            <label class="dato" for="promedio">Promedio actual:</label>
            <input type="text" class="form-control in" formControlName="promedio" disabled="disabled">
            <br>
            <input class="form-check-input in rb" type="radio" formControlName="aviso" id="aviso1" value="TRUE">
            <label class="form-check-label dato3" for="aviso1">Estoy de acuerdo con el tratamiento de mis datos
                personales sensibles y con el tratamiento de mis datos personales con fines de promoción y publicidad de
                conformidad con el aviso de privacidad de Bécalos publicado en <a
                    href="https://becalos.mx/aviso-de-privacidad/" target="_blank"><b>becalos.mx</b></a>.</label>
            <br><br>
            <input class="form-check-input in rb" type="radio" formControlName="manual" id="aviso2" value="TRUE">
            <label class="form-check-label dato3" for="aviso2">Declaro bajo protesta de decir verdad que la información
                y documentación proporcionada es verídica, <br> por lo que en caso de existir falsedad en ella se me
                dará de baja del programa y perderé el apoyo.</label>
            <br>
            <label class="dato2" for="ley">* Si alguno de los campos prellenados es incorrecto, contacta
                al coordinador de becas de tu escuela.</label>
        </form>
    </div>
    <div class="modal-footer" *ngIf="tipo==3 && !valido3">
        <button type="button" class="btn btn-outline-dark continuar"
            (click)="actualizaNuevo(content2); c('Volver')">Guardar</button>
    </div>
    <!--Error frame-->
    <div class="modal-body" *ngIf="tipo==0">
        <p class="mens">{{msj}}</p>
    </div>
    <div class="modal-footer" *ngIf="tipo==0">
        <button type="button" class="btn btn-outline-dark continuar" (click)="feedback(content4); c('Feedback')">Repotar problema</button>
        <button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
    </div>
</ng-template>

<ng-template #content2 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <img src="assets/imagenes/logo.png">
        <h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
    </div>
    <!--Mostrar versiones-->
    <div class="modal-body" *ngIf="tipo==0">
        <p class="mens">{{msj}}</p>
		<p class="menses2"><span class="menses">IMPORTANTE: </span>{{msj2}}</p>
        <div class="bot" *ngFor="let item of datos">
            <div *ngIf="item.activo==0">
                <button type="button" class="btn btn-outline-dark continuar"
                    (click)="verPerfil(item.sibid,content); c('Volver')">{{item.programa}} {{item.version}}</button>
            </div>
            <div *ngIf="item.activo==1">
                <button type="button" class="btn btn-oudtline-dark nocontinuar"
                    title="BAJA DEFINITIVA DEL PROGRAMA">{{item.programa}} {{item.version}}</button>
            </div>
            <div *ngIf="item.activo==2">
                <button type="button" class="btn btn-oudtline-dark nocontinuar"
                    title="PROGRAMA FINALIZADO">{{item.programa}} {{item.version}}</button>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="tipo==0">
        <button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
    </div>
    <div class="modal-body" *ngIf="tipo==1">
        <p class="mens">{{msj}}</p>
    </div>
    <div class="modal-footer" *ngIf="tipo==1">
        <button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
    </div>
    <div class="modal-body" *ngIf="tipo==2">
        <p class="mens">Tipo de pago: TRANSPARENCIA</p>
        <div class="bot">
            <a href="https://storage.googleapis.com/nemi_desarrollo/becalos-plantillas/Manual%20Nuevo%20Ingreso.pdf" target="_blank"><button type="button" class="btn btn-outline-dark continuar">Manual</button></a>
        </div>
        <hr>
        <p class="mens">Tipo de pago: PAGO DIRECTO</p>
        <div class="bot">
            <a href="https://sib.becalos.com/Plantillas/ManualSoyComunidad.pdf" target="_blank"><button type="button" class="btn btn-outline-dark continuar">Manual</button></a>
            <a href="https://sib.becalos.com/Plantillas/videoPagoDirecto.mp4" target="_blank"><button type="button" class="btn btn-outline-dark continuar">Video</button></a>
        </div>
    </div>
    <div class="modal-footer" *ngIf="tipo==2">
        <button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Volver</button>
    </div>
</ng-template>

<ng-template #content3 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <img src="assets/imagenes/logo.png">
        <h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
    </div>
    <!--Cerrar sesión-->
    <div class="modal-body">
        <p class="mens">{{msj}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-dark continuar" (click)="salir(); c('Salir')">Sí, salir</button>
        <button type="button" class="btn btn-outline-dark continuar" (click)="c('Regresar')">No, regresar</button>
    </div>
</ng-template>

<ng-template #content4 let-c="close" let-d="dismiss">
    <div class="modal-header">
        <img src="assets/imagenes/logo.png">
        <h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
    </div>
    <!--Cerrar sesión-->
    <div class="modal-body">
		<p class="mens">{{msj}}</p>
		<form class="form-group" [formGroup]="fb">
			<label class="dato" for="curp">Curp:</label>
			<input type="text" class="form-control in" formControlName="curp" maxlength="18"
				oninput="this.value = this.value.toUpperCase()" (input)="valida4()">
			<label class="dato" for="nombre">Nombre:</label>
			<input type="text" class="form-control in" formControlName="nombre"
				oninput="this.value = this.value.toUpperCase()" (input)="valida4()">
			<label class="dato" for="apellidoP">Apellido Paterno:</label>
			<input type="text" class="form-control in" formControlName="apellidoP"
				oninput="this.value = this.value.toUpperCase()" (input)="valida4()">
			<label class="dato" for="apellidoM">Apellido Materno:</label>
			<input type="text" class="form-control in" formControlName="apellidoM"
				oninput="this.value = this.value.toUpperCase()" (input)="valida4()">
			<label class="dato" for="correo">Correo electrónico:</label>
			<input type="text" class="form-control in" formControlName="correo"
				oninput="this.value = this.value.toLowerCase()" (input)="valida4()">
			<label class="dato" for="descripcion">Descripción del problema:</label>
			<input type="text" class="form-control in" formControlName="descripcion"
				oninput="this.value = this.value.toUpperCase()" placeholder="NO PUEDO ENTRAR A LA PLATAFORMA"
				(input)="valida4()">
		</form>
	</div>
	<div class="modal-footer" *ngIf="!valido3">
		<label class="dato">Debes llenar todos los datos para continuar.</label>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
	<div class="modal-footer" *ngIf="valido3">
		<button type="button" class="btn btn-outline-dark continuar"
			(click)="c('Enviar'); reporte(content5)">Enviar</button>
		<button type="button" class="btn btn-outline-dark continuar" (click)="cancelar(); c('Cancel')">Cancelar</button>
	</div>
</ng-template>

<!--Confirmacion de feedback-->
<ng-template #content5 let-c="close" let-d="dismiss">
	<div class="modal-header">
		<img src="assets/imagenes/logo.png">
		<h1><span title="COMUNIDAD BÉCALOS" class="azulMagentaClaro"><b>COMUNIDAD</b></span></h1>
	</div>
	<!--Correo enviado-->
	<div class="modal-body">
		<p class="mens">{{msj}}</p>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-outline-dark continuar" (click)="c('Volver')">Ok</button>
	</div>
</ng-template>

<div class="pantalla">
    <header>
        <div class="wrapper">
            <div class="logo">
                <img (click)="home()" title="Inicio" src="assets/imagenes/logo.png">
            </div>
            <nav>
                <a (click)="becas(content2)" title="Muestra la información de tu beca vigente." class="dropbtn">Mi beca</a>
                <a (click)="feedback(content4)" title="Te permite enviarnos un reporte en caso de que tengas algún problema, pregunta o comentario que hacerle a Bécalos.">¿Tienes problemas?</a>
                <a (click)="cerrarSesion(content3)" title="Salir de la comunidad de forma segura.">Cerrar sesión</a>
            </nav>
        </div>
        <hr>
        <div class="wrapper">
            <h1><span class="azulMagentaClaro">C</span><span>OMUNIDAD </span><span
                    class="azulMagentaClaro">B</span><span>ÉCALOS</span></h1>
        </div>
        <hr>
    </header>

    <div *ngIf="valido == 1">
        <iframe [src]="iFrameUrl" style="height: 900px; width: 100%;">
        </iframe>
    </div>

    <div *ngIf="valido == 0">
        <div class="perfilInicio">
            <h1>Para empezar</h1>
            <p>En la parte superior de esta pantalla encontrarás 3 botones:</p>
            <a (click)="becas(content2)" class="activo"><h3>1 - Mi beca</h3></a>
            <p>Muestra la información de tu beca vigente.</p>
            <a (click)="feedback(content4)" class="activo"><h3>2 - ¿Tienes problemas?</h3></a>
            <p>Te permite enviarnos un reporte en caso de que tengas algún problema, pregunta o comentario que hacerle a
                Bécalos.</p>
            <a (click)="cerrarSesion(content3)" class="activo"><h3>3 - Cerrar sesión</h3></a>
            <p>Salir de la comunidad de forma segura.</p>
        </div>
    </div>

    <footer>
        <div class="leyenda">            
            <p>¿Tienes dudas del proceso de tu beca?, consulta nuestros <a (click)="manuales(content2)" class="enlaces"> manuales de usuario </a></p>            
            <p>Consulta nuestro <a href="https://becalos.mx/aviso-de-privacidad/" target="_blank" class="enlaces"> aviso de privaciad </a></p>
        </div>
        <div class="copy">
            <tr class="logos" valign="top">
                <td class="enlace" valign="top"><a href="https://www.facebook.com/becalos" target="_blank"><img
                            alt="Facebook" height="32"
                            src="https://conexion.fundaciontelevisa.org/becalos/ipn/assets/images/facebook2x.png"
                            style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                            title="Facebook" width="32" /></a></td>
                <td class="enlace" valign="top"><a href="https://instagram.com/becalos_mx" target="_blank"><img
                            alt="Instagram" height="32"
                            src="https://conexion.fundaciontelevisa.org/becalos/ipn/assets/images/instagram2x.png"
                            style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                            title="Instagram" width="32" /></a></td>
                <td class="enlace" valign="top"><a href="https://twitter.com/becalosmx" target="_blank"><img
                            alt="Twitter" height="32"
                            src="https://conexion.fundaciontelevisa.org/becalos/ipn/assets/images/twitter2x.png"
                            style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                            title="Twitter" width="32" /></a></td>
                <td class="enlace" valign="top"><a href="https://www.youtube.com/user/Becalosmx" target="_blank"><img
                            alt="YouTube" height="32"
                            src="https://conexion.fundaciontelevisa.org/becalos/ipn/assets/images/youtube2x.png"
                            style="text-decoration: none; -ms-interpolation-mode: bicubic; height: auto; border: 0; display: block;"
                            title="YouTube" width="32" /></a></td>
            </tr>
            <p><b>C</b>OMUNIDAD <b>B</b>ÉCALOS <b>{{anio}}</b></p>
        </div>
    </footer>
</div>


<!--
src ='https://pagodirecto.becalos.com/PagoDirecto/free/acceso/index.jsp?json={%20%22class%22:%20%22com.nemi.becalos.dao.model.altasib.json.LoginBecarioInfo%22,%20%22sibId%22:464963,%20%22programaId%22:%20359,%20%22version%22:%20%222021%22,%20%22ciclo%22:%201%20}&sha1=ea47adfc9823aba21522177827e13719af710fe7&sha2=182a30037c16d987394f3e9deb40d97bb3651e44&fechaHora=08/03/2022%2018:20:09' style="width:100%;" height=950>
-->