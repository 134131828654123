<div class="container-fluid grisClaroF">
    <div class="leyenda grisOscuro">
        "Este sitio utiliza cookies para ayudarnos a mejorar tu experiencia cada vez que lo visites. Al continuar
        navegando en él, estarás aceptando su uso. Podrás deshabilitarlas accediendo a la configuración de tu
        navegador.”
    </div>
    <div class="aviso">
        <a href="assets/docs/aviso.pdf" target="_blank"><button type="button" class="fotterBoton">Aviso de
                privacidad</button></a>
    </div>
    <div class="copy grisOscuro">
        <p>Comunidad Bécalos {{anio | date:"yyyy"}}</p>
    </div>
</div>