import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UsuarioService } from '../Services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private usService: UsuarioService, private router: Router) { }

  canActivate(){
    console.log("Verificando acceso", this.usService.getAcceso());

    //if(this.usService.getAcceso()){
    if (true){
      console.log("Dentro");
      return true;
    }
    else{
      console.log("Fuera");
      this.router.navigate([``]);
      return false;
    }
  }
  
}
