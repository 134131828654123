// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  usuarios:{
    
    inicia: 'https://qa.sib.becalos.com/PAB/PAB_IniciaSesion.php',
    recupera: 'https://qa.sib.becalos.com/PAB/PAB_RecuperaContra.php',
    codigo: 'https://qa.sib.becalos.com/PAB/PAB_CanjeDeCodigo.php',
    mostrar: 'https://qa.sib.becalos.com/PAB/PAB_Mostrar.php',
    feedback: 'https://qa.sib.becalos.com/PAB/PAB_Feedback.php',
    correo: 'https://qa.sib.becalos.com/PAB/PAB_EnvioAccesos.php',
    acepto: 'https://qa.sib.becalos.com/PAB/PAB_Acepto.php',
    datos: 'https://qa.sib.becalos.com/PAB/PAB_ActualizaDatos.php',
    municipio: 'https://qa.sib.becalos.com/PAB/PAB_Municipio.php'
  
    /*
    inicia: 'http://qa.sib.becalos.com/PAB/PAB_IniciaSesion.php',
    recupera: 'http://qa.sib.becalos.com/PAB/PAB_RecuperaContra.php',
    codigo: 'http://qa.sib.becalos.com/PAB/PAB_CanjeDeCodigo.php',
    mostrar: 'http://qa.sib.becalos.com/PAB/PAB_Mostrar.php',
    feedback: 'http://qa.sib.becalos.com/PAB/PAB_Feedback.php',
    correo: 'http://qa.sib.becalos.com/PAB/PAB_EnvioAccesos.php',
    acepto: 'http://qa.sib.becalos.com/PAB/PAB_Acepto.php',
    datos: 'http://qa.sib.becalos.com/PAB/PAB_ActualizaDatos.php',
    municipio: 'http://qa.sib.becalos.com/PAB/PAB_Municipio.php'
*/
/*
    inicia: 'https://sib.becalos.com/PAB/PAB_IniciaSesion.php',
    recupera: 'https://sib.becalos.com/PAB/PAB_RecuperaContra.php',
    codigo: 'https://sib.becalos.com/PAB/PAB_CanjeDeCodigo.php',
    mostrar: 'https://sib.becalos.com/PAB/PAB_Mostrar.php',
    feedback: 'https://sib.becalos.com/PAB/PAB_Feedback.php',
    correo: 'https://sib.becalos.com/PAB/PAB_EnvioAccesos.php',
    acepto: 'https://sib.becalos.com/PAB/PAB_Acepto.php',
    datos: 'https://sib.becalos.com/PAB/PAB_ActualizaDatos.php',
    municipio: 'https://sib.becalos.com/PAB/PAB_Municipio.php'
    */
  }
};